import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Accordion from "../components/common/Accordion";

export default function HelpCenter({ data: { sanityHelpCenter } }) {
  return (
    <Layout>
      <div className="container max-w-[1366px] px-8 lg:px-[115px] mt-11 lg:mt-[178px] mb-5 lg:mb-[150px]">
        <p className="font-medium leading-[54px] text-3xl lg:text-[36px] txt-gradient-white">
          {sanityHelpCenter?.title} - thunder
          <span className="txt-gradient-yellow">stake</span>
        </p>
        <div className="mt-[57px]">
          {sanityHelpCenter?.faqs.map((item, index) => (
            <Accordion
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityHelpCenter {
      title
      faqs {
        answer
        question
      }
    }
  }
`;
