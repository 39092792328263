import React, { useState } from "react";

export default function Accordion({ question, answer }) {
  const [isActive, setIsActive] = useState(false);
  return (
    <div
      className={`w-full duration-1000 ease-in-out transition-maxHeight mb-2 overflow-hidden ${
        isActive ? "max-h-fit" : "max-h-fit sm:max-h-fit"
      }`}
    >
      <div
        className="relative flex items-center justify-between py-[31px] rounded-[20px] cursor-pointer bg-tundora/[0.12] pl-2 sm:pl-[52px] pr-2 sm:pr-[34px]"
        onClick={() => setIsActive(!isActive)}
      >
        <p className="text-[12px] sm:text-base lg:text-[20px] font-medium text-white leading-6 lg:leading-[30px]">
          {question}
        </p>
        <span className="">
          {isActive ? (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 7L7 1L13 7" stroke="white" />
            </svg>
          ) : (
            <svg
              width="14"
              height="8"
              viewBox="0 0 14 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M13 1L7 7L1 0.999999" stroke="white" />
            </svg>
          )}
        </span>
      </div>
      {isActive && (
        <div className="mt-[43px] mb-[40px] pl-2 sm:pl-[52px] max-w-[850px]">
          <p className="text-sm text-white leading-6">{answer}</p>
        </div>
      )}
    </div>
  );
}
